import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../controllers/UserContext";
import { useNavigate } from "react-router-dom";
import DataContext from "../../controllers/DataContext";
import Cookies from "js-cookie";
import LoginImage from "../../Images/login.png";
import Logo from "../../Images/Group.svg";
import Logo1 from "../../Images/Group (1).svg";
import Logo2 from "../../Images/Group (2).svg";
import "./style.css";
import Checkbox from "@mui/material/Checkbox";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "material-ui/IconButton";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  const [inputs, setInputs] = useState({});
  const history = useNavigate();
  const { data, setData } = useContext(DataContext);
  const { updateUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedCredentials = JSON.parse(
      localStorage.getItem("storedCredentials")
    );
    if (storedCredentials) {
      setInputs(storedCredentials);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (Cookies.get("access_token")) {
      history("/sidebar");
    }
  }, [history]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    if (!event.target.checked) {
      localStorage.removeItem("storedCredentials");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setData((prev) => ({ ...prev, LoginInfo: inputs }));

    if (rememberMe) {
      localStorage.setItem("storedCredentials", JSON.stringify(inputs));
    } else {
      localStorage.removeItem("storedCredentials");
    }

    try {
      const response = await fetch("https://api2.genesysailabs.com/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(inputs),
      });

      const userData = await response.json();

      if (userData.login) {
        updateUser(userData);
        localStorage.setItem("user_details", JSON.stringify(userData?.user));
        setData((prev) => ({ ...prev, LoginData: userData }));

        Cookies.set("access_token", userData?.access_token, { expires: 1 });
        Cookies.set(
          "common_data",
          `${userData?.clinic[1]}#${userData?.user[2]}`,
          { expires: 1 }
        );
        localStorage.setItem("clinic", JSON.stringify(userData?.clinic));

        history(
          userData?.user[5] === "clinic_admin" ? "/admin-dashboard" : "/welcome"
        );
      } else {
        alert("Invalid Credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred during login. Please try again.");
    }
  };

  return (
    <div className="login-div">
      <div className="login-image-div">
        <img className="img-image" src={LoginImage} alt="login" />
      </div>
      <div className="login-container">
        <div className="login-container-img">
          <div className="head-div">
            <div className="head">Welcome to</div>
            <div className="head-box">
              <div>
                <img className="img-div-logo" src={Logo2} alt="logo2" />
                <img
                  className="img-div"
                  src={Logo}
                  style={{ marginLeft: "13px" }}
                  alt="logo"
                />
                <img
                  className="img-div"
                  src={Logo1}
                  style={{ marginLeft: "10px" }}
                  alt="logo1"
                />
              </div>
              <div className="svg-container">AI ENHANCED EMBRYO SELECTION</div>
            </div>
          </div>
          <div className="contain12">
            <h2 className="txt8-login">Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="in-box-login">
                <div>
                  <label style={{ display: "block" }}>User ID</label>
                  <input
                    className="inpt"
                    name="username"
                    value={inputs.username || ""}
                    type="text"
                    onChange={handleChange}
                  />
                </div>

                <div style={{ marginTop: 13 }}>
                  <label style={{ display: "block" }}>Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      className="inpt"
                      name="password"
                      value={inputs.password || ""}
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                    />
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: 10,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
                <div className="forgot-password-div">
                  <div>
                    <Checkbox
                      style={{ marginRight: 10 }}
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    Remember Me
                  </div>
                  <div className="forgot-password" onClick={handleOpen}>
                    Forgot password?
                  </div>
                </div>
                <button className="btn1-login" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {open && (
        <ForgotPassword
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default Login;
