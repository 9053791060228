import React, { useState, useEffect, useMemo } from "react";
import "../../Embryo/index.css";
import Profile from "../../../Images/profile-image.png";
import {
  Container,
  styled,
  Box,
  Typography,
  Button,
  InputAdornment,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  AccountCircle,
  AccountTreeOutlined,
  Delete,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Pagination from "@mui/material/Pagination";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";
import { DeleteForever } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { fetchJSON } from "../../../controllers/Essentials";
import TableData from "../../Common/EmployeeData";

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",

    marginTop: useTheme().spacing(2),
    marginRight: useTheme().spacing(2),
  },
  roundedPagination: {
    background: "none",
    "& .MuiPaginationItem-root": {
      border: "none",
      outline: "none",
      borderRadius: "8px",
      color: "#2279F5",
      backgroundColor: "#FFFFFF",
      "&:hover": {
        background: "#FFFFFF",
        borderColor: "#2279F5",
      },
      "& button": {
        borderColor: "#2279F5",
      },
      "&.Mui-selected": {
        color: "#2279F5",
        border: "1px solid #2279F5",
        background: "#FFFFFF",
      },
    },
  },
}));

const FormHeader = styled(Container)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FormTable = styled(Container)({
  width: "95%",
  background: "#FFFFFF",
  boxSizing: "border-box",
  border: "1px solid #E2E5E9",
  borderRadius: "12px",
  padding: "12px",
});

const TableRows = styled(TableRow)({
  width: "95%",
  background: "#FFFFFF",
  boxSizing: "border-box",
  border: "1px solid #E2E5E9",
  borderRadius: "12px",
  padding: "12px",
});

const Text = styled(TextField)({
  width: "88px",
  height: "44px",
  background: "#FFFFFF",
});

const BoxContainer = styled(Button)({
  width: 80,
  height: 32,
  background: "linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%)",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  boxShadow: "0px 2px 40px rgba(34, 121, 245, 0.15)",
  borderRadius: "12px",
});

const StyledTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#FFFFFF",
});

const ActivityDetails = ({ setSelectedButton, setSelectedItem }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    let patientInfo = JSON.parse(localStorage.getItem("employee"));
    const date = new Date().toISOString().slice(0, 10);
    fetchJSON(
      "activitylog/filter?employee_name=" +
        patientInfo.EmployeeEmail +
        "&action_date=" +
        patientInfo.LoginDate,
      "GET",
      ""
    )
      .then((data) => {
        setEmployeeDetails(data.activity_details);
      })
      .catch((err) => {
        alert("refresh and login again");
      });
  }, []);

  const removePrefix = (data) => {
    const match = data.match(/\d+_(.+)/);
    return match ? match[1] : data;
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterOptionChange = (event) => {
    setFilterOptions(event.target.value);
  };

  const filteredProducts = useMemo(() => {
    return employeeDetails.filter((employee) =>
      employee.PatientName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [employeeDetails, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClick = () => {
    setSelectedButton(7);
  };

  const rows = useMemo(() => {
    return filteredProducts.slice(
      (page - 1) * rowsPerPage,
      (page - 1) * rowsPerPage + rowsPerPage
    );
  }, [filteredProducts, page, rowsPerPage]);

  return (
    <Box
      sx={{
        width: "73%",
        height: 720,
        margin: "20px auto",
        background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        borderRadius: "12px",
        "@media (max-width: 1540px)": {
          height: "645px", // Adjust the width as needed for the larger screen size
        },
      }}
    >
      <FormHeader>
        <div>
          <button onClick={handleClick} className="back-button">
            Back
          </button>
        </div>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Activity Details
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              marginTop: "32px",
            }}
          ></Box>
        </Box>
      </FormHeader>

      <Container
        sx={{
          "@media (max-width: 1540px)": {
            height: "453px",
            overflowY: "auto",
            marginTop: "5px",
          },
        }}
      >
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
          <TableHead sx={{ marginBottom: "0px", borderBottom: "none" }}>
            <TableRow>
              <TableCell style={{ fontWeight: 600 }}># </TableCell>
              <TableCell style={{ fontWeight: 600 }}>Patient ID</TableCell>
              <TableCell style={{ fontWeight: 600 }}>Patient Name</TableCell>
              <TableCell style={{ fontWeight: 600 }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((item, index) => (
              <TableRows key={index}>
                <TableCell
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                    border: "1px solid #E2E5E9",
                    borderRight: "none",
                  }}
                >
                  {(page - 1) * rowsPerPage + index + 1}
                </TableCell>
                <TableCell sx={{ borderTop: "1px solid #E2E5E9" }}>
                  <Box>
                    <AccountCircle />
                    <span style={{ marginLeft: "5px" }}>
                      {item.PatientId ? removePrefix(item.PatientId) : ""}
                    </span>
                  </Box>
                </TableCell>
                <TableCell sx={{ borderTop: "1px solid #E2E5E9" }}>
                  {item.PatientName}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #E2E5E9",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    borderRight: "none",
                    padding: "8px",
                  }}
                >
                  {item.Action} ({item.Count})
                </TableCell>
              </TableRows>
            ))}
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <Pagination
            className={classes.roundedPagination}
            count={Math.ceil(filteredProducts.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </Container>
    </Box>
  );
};

export default ActivityDetails;
