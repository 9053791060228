import { Delete } from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Box, styled, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import EmployeeForm from "./EmployeeForm";
import { fetchJSON } from "../../controllers/Essentials";
import { Button } from "material-ui";

const TableRows = styled(TableRow)({
  width: "95%",
  background: "#FFFFFF",
  boxSizing: "border-box",
  border: "1px solid #E2E5E9",
  borderRadius: "12px",
  padding: "12px",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 196,
};

const TableData = ({ item, index, page, setDelete, setSelectedButton }) => {
  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditButton = () => {
    // Cookies.set("patient", item[0], { expires: 1 });
    localStorage.setItem("employee", JSON.stringify(item));

    setIsOpen(true);
    // history("/upload-images");
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleReport = () => {
    localStorage.setItem("patient", JSON.stringify(item));

    // history("/report");
  };
  const handleYesClick = async () => {
    // Perform delete operation
    await deleteEmployee(item);
    // Close the modal
    handleClose();
  };

  const handleNoClick = () => {
    // Close the modal
    handleClose();
  };
  const deleteEmployee = async (item) => {
    let hit = await fetchJSON("user/delete", "POST", {
      user_id: item.id,
    });
    setDelete((prevState) => !prevState);
  };

  return (
    <>
      <TableRows className="table-animation">
        <TableCell
          sx={{
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            border: "1px solid #E2E5E9",
            borderRight: "none",
          }}
        >
          {(page - 1) * 6 + index + 1}
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid #E2E5E9",
          }}
        >
          {item.employeeid.split("_")[1]}{" "}
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid #E2E5E9",
          }}
        >
          {item.fullname}
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid #E2E5E9",
          }}
        >
          {item.email}
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid #E2E5E9",
          }}
        >
          {item.mobile}
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid #E2E5E9",
          }}
        >
          {capitalizeFirstLetter(item.accesslevel)}
        </TableCell>

        <TableCell
          sx={{
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
            border: "1px solid #E2E5E9",
            borderLeft: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "34px",
                background: "#E7F0FE",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#2279F5",
                cursor: "pointer",
              }}
              onClick={handleEditButton}
            >
              <BorderColorOutlinedIcon sx={{}} />
            </Box>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "34px",
                background: "#FEE6E7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FB3B42",
                cursor: "pointer",
                padding: "8px",
              }}
              onClick={handleOpen}
            >
              <Delete sx={{}} />
            </Box>
          </Box>
        </TableCell>
      </TableRows>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              style={{ marginLeft: 103, fontSize: 28 }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Are you sure want to delete?
            </Typography>
            <div className="popup-div">
              <button className="popup-btn" onClick={handleNoClick}>
                No
              </button>
              <button className="popup-btn" onClick={handleYesClick}>
                Yes
              </button>
            </div>
          </Box>
        </Modal>
      )}
      <Modal open={isOpen}>
        <div className="container1">
          <div className="out-box3 ">
            <div className="contain1-3 ">
              <EmployeeForm
                setDelete={setDelete}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TableData;
