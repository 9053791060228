import React, { useState, useEffect } from "react";
import "../../Embryo/index.css";
import {
  Container,
  styled,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
    // marginTop: theme.spacing(2),
  },
  roundedPagination: {
    background: "none",
    "& .MuiPaginationItem-root": {
      border: "none",
      outline: "none",
      borderRadius: "8px",
      color: "#2279F5",
      backgroundColor: "#FFFFFF", // Change the background color here
      "&:hover": {
        background: "#FFFFFF",
        borderColor: "#2279F5", // Change the hover background color here
      },
      "& button": {
        borderColor: "#2279F5", // Set the desired border color
      },
      "&.Mui-selected": {
        color: "#2279F5",
        border: "1px solid #2279F5",
        background: "#FFFFFF", // Change the selected text color here
      },
    },
  },
  root: {
    "& .MuiInputBase-root": {
      border: "none", // Remove the border
    },
    "& .MuiInputBase-input": {
      background: "#FFFFFF",
      textAlign: "center", // Optional: Adjust the input padding if needed
    },
  },
  textField: {
    paddingBottom: "10px",
  },
}));

const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
  width: 270,
  height: 880,
  borderRadius: "0px 12px 12px 0px",
  marginTop: "40px",
  borderImageSource: `linear-gradient(to left, #CEE1FD 8.33%, #CCF6FF 91.67%)`,
  borderImageSlice: 1,
  border: "2px solid transparent",
});

const FormHeader = styled(Container)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const BoxContainer = styled(Button)({
  width: 196,
  height: 48,
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: "12px",
  display: "flex",
  alignItems: "left",
  marginTop: "40px",
  border: "none",
  outline: "none",
  justifyContent: "flex-start",
});

const BoxContainer1 = styled(Box)({
  width: "32px",
  height: "32px",
  background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
  borderRadius: "40px",
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  margin: "8px 12px 8px 8px",
});

const StyledTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6C7C93",
});

const AdminDashboard = () => {
  const history = useNavigate();

  const [clinicData, setClinicData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedClinic, setSelectedClinic] = useState();
  const [back, setBack] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setClinicData([]);
    setSelectedClinic(false);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api2.genesysailabs.com/clinics_data",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("access_token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setClinicData(data.clinic);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [back]);
  const handleBack = () => {
    setBack(!back);
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1); // Reset page when search query changes
  };
  const filteredClinics = clinicData?.filter((clinic) => {
    const matchesSearchQuery =
      clinic.clinic_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      clinic.full_name?.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesSearchQuery;
  });

  const totalPages = Math.ceil(filteredClinics?.length / rowsPerPage);

  const paginatedClinics = filteredClinics?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClinicClick = async (clinicId) => {
    setSelectedClinic(true);
    try {
      const response = await fetch(
        `https://api2.genesysailabs.com/clinic_admin/patient/get?clinicid=${clinicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("access_token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setClinicData(data.patients);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error fetching clinic data:", error);
    }
  };

  const handlePatientClick = (data) => {
    const valuesArray = Object.values(data);
    localStorage.setItem("patient", JSON.stringify(valuesArray));
    console.log(valuesArray, "valuesArray");
    history("/patient-data");
  };
  return (
    <>
      {selectedClinic ? (
        <Box sx={{ display: "flex" }}>
          <Box>
            <StyledContainer>
              <BoxContainer
                variant="text"
                sx={{ marginTop: "40px", outlineStyle: "none" }}
                style={{
                  background:
                    selectedButton === 1 || selectedButton === 2
                      ? "linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%)"
                      : "rgba(255, 255, 255, 0.8)",
                }}
              >
                <BoxContainer1
                  style={{
                    background:
                      selectedButton === 1
                        ? "#FFFFFF"
                        : "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
                  }}
                ></BoxContainer1>
                <StyledTypography
                  sx={{
                    color:
                      selectedButton === 1 || selectedButton === 2
                        ? "#ffffff"
                        : "#6C7C93",
                  }}
                  variant="h6"
                  component="h6"
                >
                  Clinics
                </StyledTypography>
              </BoxContainer>
            </StyledContainer>
          </Box>

          <Box sx={{ width: "77%", height: "100%" }}>
            <div
              style={{
                cursor: "pointer",
                paddingLeft: 50,
                marginTop: 40,
                textDecoration: "underline",
              }}
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </div>

            <Box
              sx={{
                width: "94%",
                height: "100%",
                margin: "40px auto",
                background:
                  "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
                borderRadius: "12px",
              }}
            >
              <FormHeader>
                <Box sx={{ marginTop: "32px" }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Patient List
                  </Typography>
                </Box>
                <Box
                  maxWidth="md"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <TextField
                    variant="standard"
                    type="search"
                    placeholder="Search by patient name"
                    className={classes.textField}
                    onChange={handleSearch}
                    value={searchQuery}
                    sx={{
                      width: 300,
                      height: 44,
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      marginRight: "20px",
                      paddingTop: "10px",
                      paddingLeft: "15px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ paddingRight: "20px" }}
                          position="end"
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </FormHeader>

              <Container>
                <Table
                  sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
                >
                  <TableHead sx={{ marginBottom: "0px", borderBottom: "none" }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: 600 }}>
                        Patient id
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Full name
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Date of join
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Created by
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Created date
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Mobile</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        No of scan
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {paginatedClinics?.map((clinic) => (
                      <TableRow
                        className="table-animation"
                        style={{
                          background: "white",
                          cursor: "pointer",
                          border: "none",
                          borderRadius: "12%",
                        }}
                        key={clinic.id}
                        onClick={() => handlePatientClick(clinic)}
                      >
                        <TableCell>{clinic.patient_id}</TableCell>
                        <TableCell>{clinic.full_name}</TableCell>
                        <TableCell>{clinic.date_of_birth}</TableCell>
                        <TableCell>{clinic.created_by}</TableCell>
                        <TableCell>{clinic.created_date}</TableCell>
                        <TableCell>{clinic.mobile}</TableCell>
                        <TableCell>{clinic.no_of_scan}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Container>

              <div className={classes.pagination}>
                <Pagination
                  className={classes.roundedPagination}
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box>
            <StyledContainer>
              <BoxContainer
                variant="text"
                sx={{ marginTop: "40px", outlineStyle: "none" }}
                style={{
                  background:
                    selectedButton === 1 || selectedButton === 2
                      ? "linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%)"
                      : "rgba(255, 255, 255, 0.8)",
                }}
              >
                <BoxContainer1
                  style={{
                    background:
                      selectedButton === 1
                        ? "#FFFFFF"
                        : "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
                  }}
                ></BoxContainer1>
                <StyledTypography
                  sx={{
                    color:
                      selectedButton === 1 || selectedButton === 2
                        ? "#ffffff"
                        : "#6C7C93",
                  }}
                  variant="h6"
                  component="h6"
                >
                  Clinics
                </StyledTypography>
              </BoxContainer>
            </StyledContainer>
          </Box>

          <Box sx={{ width: "77%", height: "100%" }}>
            <Box
              sx={{
                width: "94%",
                height: "100%",
                margin: "40px auto",
                background:
                  "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
                borderRadius: "12px",
              }}
            >
              <FormHeader>
                <Box sx={{ marginTop: "32px" }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Clinic List
                  </Typography>
                </Box>
                <Box
                  maxWidth="md"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <TextField
                    variant="standard"
                    type="search"
                    placeholder="Search by clinic name"
                    className={classes.textField}
                    onChange={handleSearch}
                    value={searchQuery}
                    sx={{
                      width: 300,
                      height: 44,
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      marginRight: "20px",
                      paddingTop: "10px",
                      paddingLeft: "15px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ paddingRight: "20px" }}
                          position="end"
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </FormHeader>

              <Container>
                <Table
                  sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
                >
                  <TableHead sx={{ marginBottom: "0px", borderBottom: "none" }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: 600 }}>
                        Clinic Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Address</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>City</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>State</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Country</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Doctor Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Doctor Mobile
                      </TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Contact Person{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {paginatedClinics?.map((clinic) => (
                      <TableRow
                        style={{
                          background: "white",
                          cursor: "pointer",
                        }}
                        key={clinic.id}
                        onClick={() => handleClinicClick(clinic.clinic_id)}
                      >
                        <TableCell>{clinic.clinic_name}</TableCell>
                        <TableCell>{clinic.address}</TableCell>
                        <TableCell>{clinic.city}</TableCell>
                        <TableCell>{clinic.state}</TableCell>
                        <TableCell>{clinic.country}</TableCell>
                        <TableCell>{clinic.doctor_name}</TableCell>
                        <TableCell>{clinic.doctor_mobile}</TableCell>
                        <TableCell>{clinic.contact_person}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Container>

              <div className={classes.pagination}>
                <Pagination
                  className={classes.roundedPagination}
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AdminDashboard;
