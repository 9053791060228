import React from 'react'

const NotFound = () => {
  return (
    <div style={{display:"flex" ,justifyContent:"center",alignItems:"center",height:"88vh"}}>
        <h2 style={{height: "57px",
  fontWeight: 700,
  fontSize: "45px",
  fontFamily: "Inter",
  lineHeight: "150%",
  color: "#00D2FE",}}>Page Not Found</h2>
    </div>
  )
}

export default NotFound